import React, { FC } from 'react';
import { ButtonColors, ButtonFormats } from '@mts-ds/components/dist/types/components/button/enums';

import ContentFilter from '../../../../components_/Content/ContentFilter';
import FormRow from '../../../../components_/Form/FormRow';
import FormCol from '../../../../components_/Form/FormCol';
import Input from '../../../../components_/Fields/Input';
import Button from '../../../../components_/Fields/Button';
import DropdownTree from '../../../../components_/Fields/Tree';

import { IProps as IListProps } from './';
import { makeStyles } from '@material-ui/core/styles';
import checkFilter from '../../../../utils/checkFilter';
import Tooltip from '../../../../components_/Tooltip';
import { MtsButton } from '@mts-ds/components-react';
import Icon from '../../../../components_/Icon';

interface IProps {
  data: IListProps['data'];
  filter: any;
  list: IListProps['list']['data'];
  isLoadingExportList: boolean;
  onChange: any;
  onClickCreate: () => void;
  onClickExport: () => void;
  onReset: () => void;
}
const useStyles = makeStyles(({ spacing }) => ({
  addButton: {
    float: 'right',
    marginBottom: '0.25rem',
    marginLeft: '1rem'
  },
  exportButton: {
    float: 'right',
    marginBottom: '0.25rem'
  },
  resetButton: {
    marginBottom: '0.25rem'
  }
}));

const Filter: FC<IProps> = ({
  data,
  filter,
  list,
  isLoadingExportList,
  onChange,
  onClickCreate,
  onClickExport,
  onReset
}) => {
  const classes = useStyles();
  const { isShowReset, isDownload } = checkFilter(filter, [], list);
  const { fullTextSearch, departmentId } = filter;
  const { departments } = data;

  return (
    <ContentFilter>
      <FormRow alignItems="flex-end">
        <FormCol xs={3}>
          <Input
            name="fullTextSearch"
            type="search"
            placeholder="Найти сотрудника"
            clear
            value={fullTextSearch}
            onMtsChange={onChange}
          />
        </FormCol>
        <FormCol xs={3}>
          <DropdownTree
            name="departmentId"
            label="Подразделение"
            placeholder="Выберите подразделение"
            treeHierarchy={departments}
            value={departmentId}
            onMtsChange={onChange}
          />
        </FormCol>
        {/*<FormCol style={{minWidth: '229px'}} zeroMinWidth={false} xs={2}>*/}
        {/*  {isShowReset && (*/}
        {/*    <Button*/}
        {/*      className={classes.resetButton}*/}
        {/*      color={'ghost' as ButtonColors}*/}
        {/*      onClick={onReset}*/}
        {/*    >*/}
        {/*      Сбросить фильтры*/}
        {/*    </Button>*/}
        {/*  )}*/}
        {/*</FormCol>*/}
        {/*<FormCol style={{minWidth: '214px'}} zeroMinWidth={false} justify={"flex-end"} xs={4}>*/}
        {/*  <FormCol justify={"flex-end"} xs={3}>*/}
        {/*    <Button*/}
        {/*        className={classes.exportButton}*/}
        {/*        icon="list"*/}
        {/*        color={'secondary' as ButtonColors}*/}
        {/*        disabled={isDownload}*/}
        {/*        loading={isLoadingExportList}*/}
        {/*        onClick={onClickExport}*/}
        {/*    >*/}
        {/*      Экспорт в CSV*/}
        {/*    </Button>*/}
        {/*</FormCol>*/}
        {/*<FormCol style={{minWidth: '274px'}} zeroMinWidth={false} justify={"flex-end"} xs={2}>*/}
        <FormCol justify={'flex-end'} xs={6}>
          <Button
            className={classes.addButton}
            icon="plus"
            color={'primary' as ButtonColors}
            onClick={onClickCreate}
          >
            Добавить сотрудника
          </Button>
          <Button
            className={classes.exportButton}
            icon="list"
            color={'secondary' as ButtonColors}
            disabled={isDownload}
            loading={isLoadingExportList}
            onClick={onClickExport}
          >
            Экспорт в CSV
          </Button>
        </FormCol>
      </FormRow>
    </ContentFilter>
  );
};

export default Filter;
